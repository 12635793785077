var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form"},[(_vm.item.showContractor)?_c('a-form-model-item',{ref:"contractor",staticClass:"form_item",attrs:{"label":"Клиент","prop":"contractor","rules":{
            required: true,
            message: 'Обязательно для заполнения',
            trigger: 'blur'
        }}},[_c('div',{staticClass:"flex items-center"},[_c('a-select',{staticClass:"contractor_select",staticStyle:{"width":"100%"},attrs:{"size":"large","loading":_vm.typeLoader,"show-search":"","filter-option":false,"getPopupContainer":_vm.getPopupContainer},on:{"change":_vm.selectContractor,"popupScroll":_vm.getDataScrollHandler,"search":_vm.contractorSearchHandler},scopedSlots:_vm._u([{key:"dropdownRender",fn:function(items){return _c('div',{},[_c('v-nodes',{attrs:{"vnodes":items}}),(_vm.typeLoader)?_c('div',{staticClass:"flex justify-center"},[_c('a-spin',{attrs:{"size":"small"}})],1):_vm._e()],1)}}],null,false,792190136),model:{value:(_vm.form.contractor),callback:function ($$v) {_vm.$set(_vm.form, "contractor", $$v)},expression:"form.contractor"}},[_vm._l((_vm.contractorList),function(item){return _c('a-select-option',{key:item.id,attrs:{"value":item.id}},[_vm._v(" "+_vm._s(item.string_view)+" ")])}),_c('div',{staticClass:"flex justify-center",attrs:{"slot":"notFoundContent"},slot:"notFoundContent"},[(!_vm.typeLoader)?_c('a-empty',{attrs:{"description":_vm.$t('no_data')}}):_vm._e()],1)],2),_c(_vm.clientFormWidget,{ref:"clientForm",tag:"component",staticClass:"ml-2",attrs:{"updateContractor":_vm.updateContractor,"contractor":_vm.form.contractor,"mainForm":_vm.form,"item":_vm.item}})],1),(_vm.clientFormWidget)?_c('div',{staticClass:"flex mt-2"},[_c('span',{staticClass:"blue_color cursor-pointer flex items-center text-xs",on:{"click":function($event){return _vm.addClients()}}},[_c('a-icon',{staticClass:"mr-1",attrs:{"type":"plus"}}),_vm._v(" Добавить клиента ")],1)]):_vm._e()]):_vm._e(),(_vm.item.showContractorMember)?[_c('a-form-model-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.visibleContractorMember),expression:"visibleContractorMember"}],ref:"contractor_member",staticClass:"form_item",attrs:{"label":"Контрагент","prop":"contractor_member","rules":{
                required: true,
                message: 'Обязательно для заполнения',
                trigger: 'blur'
            }}},[_c('a-select',{attrs:{"size":"large","loading":_vm.memberLoader},model:{value:(_vm.form.contractor_member),callback:function ($$v) {_vm.$set(_vm.form, "contractor_member", $$v)},expression:"form.contractor_member"}},_vm._l((_vm.memberList),function(item){return _c('a-select-option',{key:item.id,attrs:{"value":item.id}},[_vm._v(" "+_vm._s(item.string_view)+" ")])}),1)],1)]:_vm._e(),(_vm.item.showContract)?[_c('a-form-model-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.visibleContract),expression:"visibleContract"}],ref:"contract",staticClass:"form_item",attrs:{"label":"Соглашение","help":"При изменении соглашения цена в заказе будет пересчитана","prop":"contract","rules":{
                required: true,
                message: 'Обязательно для заполнения',
                trigger: 'blur'
            }}},[_c('a-select',{attrs:{"size":"large","loading":_vm.contractLoading},on:{"change":function($event){return _vm.changeContract($event, _vm.contractList)}},model:{value:(_vm.form.contract),callback:function ($$v) {_vm.$set(_vm.form, "contract", $$v)},expression:"form.contract"}},_vm._l((_vm.contractList),function(item){return _c('a-select-option',{key:item.id,attrs:{"value":item.code}},[_vm._v(" "+_vm._s(item.string_view)+" ")])}),1)],1)]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }